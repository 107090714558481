import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { TResume } from "./resume";

interface ResumesState {
  resumes: TResume[];
  setValue: (resumes: TResume[]) => void;
}

export const useResumesStore = create<ResumesState>()(
  immer((set) => ({
    resumes: [],
    setValue: (resumes) => {
      set((state) => {
        state.resumes = resumes;
      });
    },
  })),
);
