"use client";

import { NextUIProvider } from "@nextui-org/react";
import { SessionProvider } from "next-auth/react";
import { AppProgressBar as ProgressBar } from "next-nprogress-bar";
import { ReactNode } from "react";

function NextAuthProvider({ children }: { children: ReactNode }) {
  return <SessionProvider>{children}</SessionProvider>;
}

export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <NextAuthProvider>
      <NextUIProvider>
        {children}
        <ProgressBar
          height="4px"
          color="#3fcf8e"
          options={{ showSpinner: false }}
          shallowRouting
        />
      </NextUIProvider>
    </NextAuthProvider>
  );
}
