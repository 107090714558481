"use client";

import { Button } from "@/components/ui/button";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { useTranslation } from "@/lib/i18n";
import { Loader2 } from "lucide-react";
import { useRouter } from "next/navigation";
import React, { useTransition } from "react";

function CarouselSize() {
  const [isPending, startTransition] = useTransition();
  const { t } = useTranslation({ ns: "website" });
  const router = useRouter();

  const examples = [
    {
      title: "noPicture001",
      link: "/create?temId=noPicture001",
      thumbnail: "https://cdn.talencat.com/app/assets/images/index/1.png",
    },
    {
      title: "noPicture002",
      link: "/create?temId=noPicture002",
      thumbnail: "https://cdn.talencat.com/app/assets/images/index/2.png",
    },
    {
      title: "rhyhorn",
      link: "/create?temId=rhyhorn",
      thumbnail: "https://cdn.talencat.com/app/assets/images/index/3.png",
    },
    {
      title: "chikorita",
      link: "/create?temId=chikorita",
      thumbnail: "https://cdn.talencat.com/app/assets/images/index/4.png",
    },
    {
      title: "leafish",
      link: "/create?temId=leafish",
      thumbnail: "https://cdn.talencat.com/app/assets/images/index/5.png",
    },
    {
      title: "glalie",
      link: "/create?temId=glalie",
      thumbnail: "https://cdn.talencat.com/app/assets/images/index/8.png",
    },
    {
      title: "rirekisho",
      link: "/create?temId=rirekisho",
      thumbnail: "https://cdn.talencat.com/app/assets/images/index/9.png",
    },
    {
      title: "azurill",
      link: "/create?temId=azurill",
      thumbnail: "https://cdn.talencat.com/app/assets/images/index/10.png",
    },
    {
      title: "rhyhornPrimary",
      link: "/create?temId=rhyhornPrimary",
      thumbnail: "https://cdn.talencat.com/app/assets/images/index/11.png",
    },
  ];

  return (
    <Carousel
      opts={{
        align: "start",
      }}
      className="w-full max-w-screen-xl"
    >
      <CarouselContent>
        {examples.map((item) => (
          <CarouselItem
            key={item.title}
            className="basis-1/3"
            onClick={() => {
              startTransition(() => {
                router.push(item.link);
              });
            }}
          >
            <div className="group/template relative">
              <img alt={item.title} src={item.thumbnail} />

              <div className=" absolute bottom-0 left-0 right-0 h-16 w-full bg-white opacity-0 group-hover/template:opacity-70"></div>

              <div className=" absolute bottom-0 left-0 right-0 hidden h-16  w-full group-hover/template:block">
                <div className="flex h-full w-full items-center justify-center opacity-100">
                  {isPending ? (
                    <Loader2 className="animate-spin" />
                  ) : (
                    <Button variant={"secondary"}>
                      {t("Start with this template")}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </CarouselItem>
        ))}
      </CarouselContent>
      <CarouselPrevious />
      <CarouselNext />
    </Carousel>
  );
}

const ResumeTemplates: React.FC<{ lng?: string }> = ({ lng }) => {
  return (
    <section className=" mx-auto my-40 max-w-screen-xl">
      <h2 className="text-center text-5xl font-semibold leading-normal text-teal-900">
        Resume Templates
      </h2>

      <div className="mt-10 flex justify-center">
        <CarouselSize />
      </div>
    </section>
  );
};

export default ResumeTemplates;
