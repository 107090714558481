import { Toaster } from "@/components/ui/sonner";
import { AppSite } from "@/lib/config";
import { serverSideTranslation } from "@/lib/i18n/server";
import { fallbackLng, languages } from "@/lib/i18n/settings";
import { cn } from "@/lib/utils";
import { GoogleAnalytics } from "@next/third-parties/google";
import "globalthis/polyfill";
import { Inter } from "next/font/google";
import Script from "next/script";
import { Suspense } from "react";
import type { SoftwareApplication, WithContext } from "schema-dts";
import CookieNotice from "../_components/CookieNotice";
import { Providers } from "../providers";

const inter = Inter({
  subsets: ["latin"],
  display: "swap",
});

export default async function BizRootLayout({
  params = {},
  children,
}: {
  params?: { lng?: string };
  children: React.ReactNode;
}) {
  const { lng = fallbackLng } = params;
  const { t } = await serverSideTranslation(lng, "website");

  const jsonLd: WithContext<SoftwareApplication> = {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    name: t("title"),
    description: t("description"),
    image:
      "https://cdn.talencat.com/app/assets/images/index/main-interface.png",
    applicationCategory: "Utilities",
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "4.7878787878",
      reviewCount: "124",
    },
    offers: {
      "@type": "Offer",
      url: AppSite,
      availability: "OnlineOnly",
      priceCurrency: "USD",
      price: "0",
      priceValidUntil: "2045-07-12",
    },
  };

  let formattedLng = lng;
  if (!languages.includes(lng)) formattedLng = fallbackLng;

  return (
    <html
      lang={formattedLng}
      className={cn(inter.className, "light")}
      style={{ colorScheme: "light" }}
    >
      <head>
        <Script id="json-ld-root" type="application/ld+json">
          {JSON.stringify(jsonLd)}
        </Script>

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin=""
        />
        <link
          href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600&family=Lato&family=Lora:ital,wght@0,400..700;1,400..700&family=Ma+Shan+Zheng&family=Merriweather&family=Noto+Sans+JP:wght@100..900&family=Noto+Sans+SC:wght@100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Noto+Serif+SC:wght@400;500;600&family=Noto+Serif:ital,wght@0,100..900;1,100..900&family=Poppins:wght@400;500;600&family=Roboto:wght@400;500&family=Sofia+Sans+Extra+Condensed:ital,wght@0,1..1000;1,1..1000&family=Zen+Kaku+Gothic+New:wght@400;500&family=Zen+Old+Mincho:wght@400;500;600&family=Zhi+Mang+Xing&display=swap&family=Source+Serif+4:ital,opsz,wght@0,8..60,200..900;1,8..60,200..900&display=swap"
          rel="stylesheet"
        />

        <Script
          strategy="beforeInteractive"
          src="https://unpkg.com/@ungap/global-this@0.4.4/min.js"
        />

        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7839329954417489"
          crossOrigin="anonymous"
        ></script>

        {process.env.NODE_ENV === "production" && (
          <GoogleAnalytics gaId="G-XFMTWQGXFW" />
        )}
      </head>

      <body className={"antialiased"}>
        <Providers>
          <main>{children}</main>
        </Providers>

        <Suspense>
          <CookieNotice />
        </Suspense>

        <Toaster position="bottom-center" />
      </body>
    </html>
  );
}
