"use client";

import { Button } from "@/components/ui/button";
import { useTranslation } from "@/lib/i18n";
import { ResourceType } from "@/lib/shared/resource-usage/constants";
import useResourceUsage from "@/lib/store/resource-usage";
import useResumes from "@/lib/swr/useResumes";
import { cn } from "@/lib/utils";
import { ArrowRight, DollarSign, FilePlus2, Loader2 } from "lucide-react";
import { useRouter } from "next/navigation";
import { useMemo, useState } from "react";

const UpgradeButton = () => {
  const { t, i18n } = useTranslation({ ns: "nav-bar" });

  const [isCustomerPortalLoading, setIsCustomerPortalLoading] = useState(false);

  const router = useRouter();

  const { subscription, checkResourceReachedLimit, resourceUsage } =
    useResourceUsage();

  const { resumes, isLoading } = useResumes();

  const isReachedResumeCreateLimit = useMemo(() => {
    return checkResourceReachedLimit(ResourceType.ResumeCreate);
  }, [subscription, resourceUsage]);

  const getCustomerPortalUrl = async () => {
    const response = await fetch("/api/payment/customer-portal-session", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const url = await response.json();
    return url;
  };

  return (
    <div
      className={cn(
        "mb-2 flex flex-col gap-2 rounded-md border-neutral-200 bg-white p-2 text-xs dark:border-neutral-800 dark:bg-zinc-900",
        subscription &&
          subscription.subscriptionId &&
          "hover:bg-neutral-100 dark:hover:bg-neutral-800",
      )}
      onClick={async () => {
        router.push("/billing");
        // if (isCustomerPortalLoading) return;
        // try {
        //   setIsCustomerPortalLoading(true);
        //   if (subscription && subscription.subscriptionId) {
        //     const url = await getCustomerPortalUrl();
        //     window.open(url, "_blank");
        //   }
        // } catch (error) {
        //   console.log(error);
        // } finally {
        //   setIsCustomerPortalLoading(false);
        // }
      }}
    >
      {!subscription && (
        <>
          <div className="flex  gap-2">
            <FilePlus2 size={16} className="shrink-0 text-primary" />
            <div className="flex flex-col gap-1">
              <p className="text-xs font-medium text-neutral-800 dark:text-neutral-100">
                {t("getUnlimitedResumes")}
              </p>
              {!isLoading && (
                <p className="text-xs text-neutral-500 dark:text-neutral-400">
                  {isReachedResumeCreateLimit
                    ? t("youveReachedTheLimit")
                    : t("youveUsed.resumes", {
                        count: resumes?.length || 0,
                      })}
                </p>
              )}
            </div>
          </div>

          <Button
            className="h-8 gap-1 bg-slate-800 text-xs hover:bg-slate-700"
            size={"sm"}
            onClick={() => {
              router.push("/upgrade");
            }}
          >
            {t("upgradeToPlus")}
            <ArrowRight size={14} />
          </Button>
        </>
      )}

      {subscription && (
        <div className="relative flex cursor-pointer gap-2 ">
          {isCustomerPortalLoading && (
            <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center">
              <Loader2 size={20} className="animate-spin" />
            </div>
          )}

          <DollarSign size={16} className="shrink-0 text-primary" />
          <div className="flex flex-col gap-1 text-xs">
            <p className="font-medium text-neutral-800 dark:text-neutral-100">
              {t("managePlan")}
            </p>
            <p className="text-neutral-500 dark:text-neutral-400">
              {t("unlimitedAccess")}
            </p>
          </div>

          <span className="absolute right-0 top-0 rounded-md bg-primary px-1  text-xs text-white">
            PLUS
          </span>
        </div>
      )}
    </div>
  );
};

export default UpgradeButton;
