import { useEffect } from "react";
import useSWR, { mutate } from "swr";
import { TResume } from "../store/resume";
import { useResumesStore } from "../store/resumes";

const fetcher = (url: string | URL | Request) =>
  fetch(url).then((res) => res.json());

export default function useResumes() {
  const { data, error, isLoading } = useSWR<{
    object: string;
    data: TResume[];
  }>(`/api/resumes`, fetcher);

  const { setValue } = useResumesStore();

  useEffect(() => {
    if (data) {
      setValue(data.data);
    }
  }, [data, setValue]);

  return {
    resumes: data?.data,
    isLoading,
    isError: error,
  };
}

export const fetchData = async (callback?: (res: Response) => void) => {
  const res = await mutate(`/api/resumes`);
  callback?.(res);
};
