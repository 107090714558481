"use client";

import UserDropdown from "@/app/_components/UserDropdown";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  changeClientSideLocale,
  getLanguage,
  useTranslation,
} from "@/lib/i18n";
import { Lng, LngMap } from "@/lib/i18n/settings";
import { cn } from "@/lib/utils";
import {
  ChevronsUpDown,
  HelpCircle,
  Home,
  LogOut,
  Mail,
  Settings,
} from "lucide-react";
import { signOut, useSession } from "next-auth/react";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React, { useEffect, useMemo, useState } from "react";
import LogoFile from "../../../public/logo/talencat-logo.jpg";
import UpgradeButton from "./UpgradeButton";

const Logo = <Image src={LogoFile} alt="TalenCat Logo" height={24} />;

enum TabType {
  Home = "home",
  CoverLetters = "coverletters",
  Settings = "settings",
}

const NavBar: React.FC = () => {
  const { t, i18n } = useTranslation({ ns: "nav-bar" });
  const { t: dashboardT } = useTranslation({ ns: "dashboard-settings" });

  const pathname = usePathname();
  const language = getLanguage();
  const { data: session } = useSession();

  const menuItems = useMemo(
    () => [
      {
        label: (
          <>
            <Home size={16} />
            <p>{t("resumes")}</p>
          </>
        ),
        href: "/home",
      },
      {
        label: (
          <>
            <Mail size={16} />
            <p>Cover Letters</p>
          </>
        ),
        href: "/coverletters",
        hidden: language !== "en",
      },
    ],
    [language, t],
  );

  const extraMenuItems = useMemo(
    () => [
      {
        label: (
          <>
            <div className="flex w-full items-center gap-2">
              <Mail size={18} />
              <p>{t("feedback")}</p>
            </div>
          </>
        ),

        href: "mailto:support@talencat.com",
        linkProps: {
          target: "_blank",
        },
      },
      {
        label: (
          <>
            <HelpCircle size={18} />
            <p>{t("helpDocs")}</p>
          </>
        ),
        href: "https://docs.talencat.com",
        linkProps: {
          target: "_blank",
        },
        extra: (
          <div className="flex flex-col  px-2 text-xs text-neutral-500">
            <p>{t("contactUsWithEmail")}</p>
            <p>support@talencat.com</p>
          </div>
        ),
      },
    ],
    [t],
  );

  const systemMenuItems = useMemo(
    () => [
      {
        label: (
          <>
            <Settings size={16} />
            <p>{t("settings")}</p>
          </>
        ),
        href: "/settings",
      },
    ],
    [t],
  );

  const [currentLng, setCurrentLng] = useState<string>(i18n.language);

  useEffect(() => {
    setCurrentLng(i18n.language);
  }, [i18n.language]);

  const handleLngOnChange = (lng: string) => {
    setCurrentLng(lng);

    changeClientSideLocale(lng as Lng);
  };

  return (
    <div className=" relative h-full w-60 shrink-0 p-4">
      <div className="flex items-center gap-2">
        {Logo}
        <p className="text-sm">TalenCat CV Maker</p>
      </div>

      <div className="mt-5 flex flex-col gap-2">
        {menuItems.map(({ label, href, hidden }) =>
          hidden ? null : (
            <Link href={href} key={href} data-disable-nprogress={true}>
              <div
                className={cn(
                  "flex items-center gap-2 rounded-md p-2 text-sm font-normal text-neutral-700 transition-colors duration-200 ",
                  "dark:text-neutral-100",
                  "border border-transparent",
                  "hover:border-neutral-200 hover:bg-neutral-50 dark:hover:border-default-300 dark:hover:bg-default",
                  pathname === href &&
                    "border-neutral-200 bg-neutral-50 dark:border-default-300 dark:bg-default",
                )}
              >
                {label}
              </div>
            </Link>
          ),
        )}
      </div>

      <div className="mt-2 flex flex-col  border-t border-neutral-200 pt-2 dark:border-neutral-800">
        {extraMenuItems.map(({ label, href, linkProps, extra }) => (
          <div key={href}>
            <Link href={href} data-disable-nprogress={true} {...linkProps}>
              <div
                className={cn(
                  "flex items-center gap-2 rounded-md p-2 text-sm font-normal text-neutral-700 transition-colors duration-200 ",
                  "dark:text-neutral-100",
                  "border border-transparent",
                  "hover:border-neutral-200 hover:bg-neutral-50 dark:hover:border-default-300 dark:hover:bg-default",
                )}
              >
                {label}
              </div>
            </Link>
            {extra}
          </div>
        ))}
      </div>

      {/* <div className="mt-2 flex flex-col gap-2 border-t border-neutral-200 pt-2 dark:border-neutral-800">
        {systemMenuItems.map(({ label, href }) => (
          <Link href={href} key={href} data-disable-nprogress={true}>
            <div
              className={cn(
                "flex items-center gap-2 rounded-md p-2 text-sm font-normal text-neutral-700 transition-colors duration-200 ",
                "dark:text-neutral-100",
                "border border-transparent",
                "hover:border-neutral-200 hover:bg-neutral-50 dark:hover:border-default-300 dark:hover:bg-default",
                pathname === href &&
                  "border-neutral-200 bg-neutral-50 dark:border-default-300 dark:bg-default",
              )}
            >
              {label}
            </div>
          </Link>
        ))}
      </div> */}

      <div className="mt-2 flex flex-col gap-2 border-t border-neutral-200 pt-2 dark:border-neutral-800">
        <div>
          <Label className="pb-1 pl-1">{dashboardT("menu.general.lang")}</Label>
          <Select
            value={currentLng}
            onValueChange={(value) => {
              handleLngOnChange(value);
            }}
          >
            <SelectTrigger>
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {Object.keys(LngMap).map((lng) => (
                <SelectItem key={lng} value={lng}>
                  {LngMap[lng]}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>

      <div className="absolute bottom-0 left-2 right-2 z-10 bg-[var(--cat-builder-bg)] pb-4">
        <UpgradeButton />

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div className="flex w-full cursor-pointer items-center justify-between rounded p-2 transition-colors hover:bg-neutral-50">
              <div className="flex items-center gap-1">
                <UserDropdown />
                <div className="flex max-w-32 flex-col">
                  <p className="text-sm">{session?.user?.name}</p>
                  <p className="truncate text-xs">{session?.user?.email}</p>
                </div>
              </div>

              <ChevronsUpDown size={18} className=" shrink-0" />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent side="right">
            <DropdownMenuItem key="settings">
              <Settings size={16} />

              <Link href="/settings" className="block h-full w-full">
                {t("settings")}
              </Link>
            </DropdownMenuItem>

            <DropdownMenuItem
              key="logout"
              color="danger"
              onClick={() => signOut()}
            >
              <LogOut size={16} />
              {t("logOut")}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
};

export default NavBar;
